<template>
  <div>
    <a-row class="left">
        <a-col class="title">91再生交易平台执行“实名认证”入驻</a-col>
        <a-col class="date"><a-icon type="clock-circle" /> 2021-12-15</a-col>
        <a-divider class="main"></a-divider>
        <div class="content">
            <p>根据<a>《电子商务法》</a>第十五条的规定：电子商务经营者应当在其首页显著位置，持续公示营业执照信息、与其经营业务有关的行政许可信息、属于依照本法第十条规定的不需要办理市场主体登记情形等信息，或者上述信息的链接标识。前款规定的信息发生变更的，电子商务经营者应当及时更新公示信息。</p>
            <p>第二十七条的规定：电子商务平台经营者应当要求申请进入平台销售商品或者提供服务的经营者提交其身份证、地址、联系方式、行政许可等真实信息，进行核验、登记，建立登记档案，并定期核验更新。</p>
            <br/>
            <p><b>为贯彻落实国家法律要求，保障平台会员的合法权益，降低网站的经营风险，91再生特作出如下通知：</b></p>
            <p>1、所有买方企业，均需要上传营业执照，完成证照OCR识别。同时买方企业的合同签署人也要进行姓名、身份证号、手机号验证，请企业人员如实填写信息。</p>
            <p>2、所有申请卖方的自然人申请，除上传身份证正反面照片外，还需要通过手机进行人脸视频识别，通过与证件信息匹配，确保此次操作人为自然人本人。</p>

            <p>3、所有交易主体均需要通过认证后才能进行平台上的交易操作。</p>
        </div>

        <a-row :gutter="20">
            <a-col :span="12">
                <div class="next" @click="$router.push('/notice-details/s2')">
                    <div class="align">
                        <p class="to">上一篇</p>
                        <p class="to-title">小规模增值税1%征收率将于2021年12月31结束</p>
                    </div>
                </div>
            </a-col>
            <a-col :span="12">
                <div class="next" @click="$router.push('/notice-details/s4')">
                    <div class="align">
                        <p class="to">下一篇</p>
                        <p class="to-title">关于个体工商户发票存证限额的通知</p>
                    </div>
                </div>
            </a-col>
        </a-row>
    </a-row>

    <a-row class="right">
        <a-col class="date">其他公告</a-col>
        <a-divider class="main"></a-divider>
        
        <a-row class="r-item" v-for="item in list" :key="item.title">
            <a-col class="to-title" @click="$router.push(item.router)">{{item.title}}</a-col>
            <a-col class="r-text">{{item.text}}</a-col>
            <a-col class="r-date"><a-icon type="clock-circle" /> 2021-12-15</a-col>
            <a-divider></a-divider>
        </a-row>
    </a-row>
  </div>
</template>

<script>
export default {
    data() {
        return {
            list: [
                    {title:'增值税1%征收率即将结束',text:'小规模增值税1%征收率将于2021年12月31结束，2022年1月1日起按3%征收...',router: '/notice-details/s2'},
                   {title:'91再生交易平台上线啦！',text:'针对再生资源行业合规乱象问题，91再生通过多年的交易经验与相关部门合作...',router: '/notice-details/s1'},
                   {title:'关于个体工商户发票存证限额的通知',text:'根据《国家税务总局关于统一小规模纳税人标准等若干增值税问题的公告》...',router: '/notice-details/s4'},
                   {title:'91再生网依法进行主体登记，履行纳税义务',text:'依据《中华人民共和国电子商务法》, 91再生交易平台依法进行主体登记，履行纳税义务...',router: '/notice-details/s5'},
               ]
        }
    }
}
</script>

<style lang="less" scoped>
.left {
    @media screen and (max-width: 1440px){
        width: 60%;
    }
    display: inline-block;
    vertical-align: top;
    width: 960px;
    margin-right: 42px;
}
.right {
    display: inline-block;
    vertical-align: top;
    @media screen and (max-width: 1440px){
        width: calc(40% - 42px);
    }
    width: 352px;
    line-height: 24px;
}
.title {
    font-weight: 700;
    font-size: 24px;
    margin-bottom: 16px;
}
.date {
    color: #AAAAAA;
    font-size: 16px;
}
.content {
    p{
        line-height: 26px;
        text-indent: 2em;
    }
    li {
        margin-left: 3.5em;
        line-height: 26px;
        list-style: initial;
    }
}
.to-title {
    cursor: pointer;
    font-weight: 700;
    color: #666666;
    font-size: 18px;
    transition: all .1s;
    &:hover {
        color: rgba(234, 164, 0, 1);
    }
}
.next {
    margin: 62px 0;
    cursor: pointer;
    border-left: 3px solid rgba(215, 215, 215, 1);
    background-color: rgba(242, 242, 242, 1);
    height: 100px;
    position: relative;
    transition: all .1s;
    .align {
        margin-left: 20px;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(0, -50%);
        .to {
            color: rgb(170, 170, 170);
            line-height: 28px;
        }
    }
    &:hover {
        background-color: rgba(68, 165, 40, 1);
        .to,.to-title {
            color: #fff !important;
        }
    }
}
.r-item {
    .r-date {
        color: #AAAAAA;
        font-size: 14px;
    }
    .r-text {
        font-size: 14px;
        color: #AAAAAA;
        margin-top: 6px;
        line-height: 24px;
    }
}
/deep/ .ant-divider-horizontal{
    margin: 12px 0;
}
.main {
    background: #d7d7d7;
    margin: 12px 0 16px 0;
}
</style>